<template>
  <div
    id="read"
    :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
      isApp ? systemBarHeight : 0
    }px`"
    v-scroll:#read-scroll="onScroll"
  >
    <read-app-head :key="navKey" :isLoading="isLoading"></read-app-head>
    <div id="read-scroll" class="read-scroll show-scroll">
      <div class="read-content">
        <transition name="slide-fade">
          <keep-alive
            ><router-view
              @goHistoryScroll="goHistoryScroll"
              @changeLoading="changeLoading"
            ></router-view
          ></keep-alive>
        </transition>
      </div>
    </div>
    <v-fab-transition>
      <v-btn
        style="bottom: 90px; right: 2rem"
        :key="$route.path"
        fab
        bottom
        right
        fixed
        small
        @click="goTop"
        v-show="scrollTop > 80"
      >
        <v-icon color="#909090">mdi-format-vertical-align-top</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import ReadAppHead from "@/views/read/components/readAppHead";
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
export default {
  name: "readIndex",
  mixins: [setSystemColor, buttonVibrate],
  components: { ReadAppHead },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
    }),
  },
  data: () => ({
    navKey: 0,
    firstCreat: true,
    scrollTop: 0,
    needRefresh: false,
    isLoading: false,
  }),
  methods: {
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        this.scrollTop = e.target.scrollTop;
      }, 111);
    },
    changeLoading(val) {
      this.isLoading = val;
    },
    goTop() {
      this.zzzShort();
      this.$vuetify.goTo(0, {
        container: "#read-scroll",
        duration: 800,
        easing: "easeInOutCubic",
      });
    },
    goHistoryScroll(lastScrollTop) {
      if (this.needRefresh) {
        console.info("do refresh ...");
        this.navKey += 1;
        document.getElementById("read-scroll").scrollTo(0, 0);
      } else {
        this.$vuetify.goTo(lastScrollTop, {
          container: "#read-scroll",
          duration: 800,
          easing: "easeInOutCubic",
        });
      }
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", true);
      this.$store.dispatch("setAppSystemBarColor", "#eaf5f5");
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
    },
  },
  created() {
    this.init();
    console.info("read组件被创建");
  },
  mounted() {
    console.info("read组件被挂载");
  },
  activated() {
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
      console.info("read组件被激活");
    }
  },
  deactivated() {
    console.info("read组件被暂停");
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (
        from.name === "Read" ||
        from.name === "readText" ||
        from.name === "readAll"
      ) {
        if (
          to.name === "Read" ||
          to.name === "readText" ||
          to.name === "readAll"
        ) {
          this.needRefresh = true;
          console.log("needRefreshSelf：", this.needRefresh);
        }
        return;
      }
      if (from.name !== "Reader" && from.name !== "classDetail") {
        this.needRefresh = true;
      } else {
        this.needRefresh = false;
      }
      console.log("needRefresh：", this.needRefresh);
    },
  },
};
</script>

<style lang="scss" scoped>
#read {
  height: 100%;
  background-color: #8ca0ff;
  overflow: hidden;
  .read-scroll {
    height: calc(100% - 104px);
    background-color: #ffffff;
    overflow-y: auto;
    .read-content {
      width: 100%;
      max-width: 100%;
      min-height: 100%;
      word-wrap: break-word;
    }
  }
}
</style>

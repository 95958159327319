<template>
  <v-app-bar
    :color="appThemeConfig.headColor"
    :dark="appThemeConfig.dark"
    :fade-img-on-scroll="false"
    class="app-bar rounded-b-lg"
    :src="require('@/assets/background/colorful/bg-star.jpg')"
    elevate-on-scroll
    scroll-target="#read-scroll"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        :src="require('@/assets/background/colorful/bg-star.jpg')"
        gradient="to bottom, rgba(250,250,250,.6), rgba(255,255,255,0.7), rgba(255,255,255,0.9)"
      >
      </v-img>
    </template>

    <v-app-bar-nav-icon @click="switchDrawer"></v-app-bar-nav-icon>

    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      absolute
      top
      color="deep-purple accent-1"
    ></v-progress-linear>

    <v-tabs @change="tabChange" v-model="tab" right :dark="false">
      <v-tabs-slider color="indigo lighten-2"></v-tabs-slider>
      <v-tab color="indigo lighten-2" @click="switchTab" to="/read/text"
        >每日精读
      </v-tab>
      <v-tab color="indigo lighten-2" @click="switchTab" to="/read/all"
        >全部
      </v-tab>
    </v-tabs>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "readAppHead",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({
    tab: null,
    bgImg: {
      purple: require("@/assets/background/purple/bg-star.jpg"),
      colorful: require("@/assets/background/colorful/bg-star.jpg"),
    },
  }),
  methods: {
    switchDrawer() {
      this.$store.dispatch("setShowDrawer", !this.showDrawer);
    },
    switchTab() {
      console.log("==", this.tab);
    },
    tabChange(e) {
      console.log(e);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-tab {
  min-width: 75px;
}

.app-bar {
  z-index: 3;
}
</style>
